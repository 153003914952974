<template>
  <CollectionDetails is-community-collections />
</template>
<script>
import CollectionDetails from '@/components/CollectionDetails';
export default {
  name: 'CommunityCollectionDetails',
  components: {
    CollectionDetails,
  },
};
</script>
<style scoped lang="scss">
</style>
